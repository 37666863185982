import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["recurrenceType", "endsOnContainer", "endsOn", "startingAt", "endsAt"]

  connect() {
    this.updateMinimumEnds();
    this.toggleDateFields(); // Ensure correct initial state
  }

  startingAtChanged() {
    this.updateMinimumEnds();
    // Optionally, adjust starts_on and ends_on values based on the new starting_at value
    this.adjustStartsAtAndEndsAtTimes();
  }

  updateMinimumEnds() {
    const startingAtValue = this.startingAtTarget.value;
    // Update the min attribute for ends_on
    this.endsAtTarget.setAttribute('min', startingAtValue);
    this.endsOnTarget.setAttribute('min', this.formatDateToInputValue(new Date(startingAtValue)));
  }

  adjustStartsAtAndEndsAtTimes() {
    const startingAtValue = this.startingAtTarget.value;
    const endsAtValue = this.endsAtTarget.value;
  
    // Validate the startingAt and endsAt values before proceeding
    if (!startingAtValue || !endsAtValue) return;
  
    const startingAtDate = new Date(startingAtValue);
    const endsAtDate = new Date(endsAtValue);
  
    // Check if dates are valid
    if (isNaN(startingAtDate.getTime()) || isNaN(endsAtDate.getTime())) {
      console.error("Invalid start or end date");
      return;
    }
  
    // Extract the time component from the endsAtValue
    const timeMatch = endsAtValue.match(/T(\d{2}:\d{2})/);
    const endsAtTime = timeMatch ? timeMatch[1] : null;
  
    if (endsAtDate < startingAtDate) {
      const adjustedEndsAtDate = new Date(startingAtDate.getTime());
  
      // Preserve the time part from the original endsAtDate
      adjustedEndsAtDate.setHours(endsAtDate.getHours(), endsAtDate.getMinutes(), 0, 0);
  
      this.endsAtTarget.value = this.formatDateTimeToInputValue(adjustedEndsAtDate);
    }
  }
  
  formatDateTimeToInputValue(dateTime) {
    if (isNaN(dateTime.getTime())) {
      console.error("Invalid dateTime value");
      return '';
    }
  
    // Manually format the date to preserve the local timezone
    const year = dateTime.getFullYear();
    const month = (dateTime.getMonth() + 1).toString().padStart(2, '0');
    const day = dateTime.getDate().toString().padStart(2, '0');
    const hours = dateTime.getHours().toString().padStart(2, '0');
    const minutes = dateTime.getMinutes().toString().padStart(2, '0');
  
    // Returns YYYY-MM-DDThh:mm in the local timezone
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }
  

  formatDateToInputValue(date) {
    return date.toISOString().split('T')[0]; // Converts date object to YYYY-MM-DD format
  }

  recurrenceTypeChanged() {
    this.toggleDateFields();
  }

  toggleDateFields() {
    const recurrenceType = this.recurrenceTypeTarget.value;
    const showDates = recurrenceType !== '' && recurrenceType !== 'Once';

    if (showDates) {
      this.endsOnContainerTarget.style.display = 'block';
    } else {
      this.endsOnTarget.value = null;
      this.endsOnContainerTarget.style.display = 'none';
    }
  }
}
