import { Controller } from "@hotwired/stimulus"
import Autocomplete from "bootstrap5-autocomplete"

export default class extends Controller {
  static targets = ["productTypes", "selectedProductTypes", "inputs", "quantity", "selectedList", "noResult"]

  connect() {
    this.setupAutocomplete()
    this.selectedProductTypes = new Map()
    this.deletedProductTypes = new Map()
    this.noResultTarget.style.display = "none"
    this.selectOnConnect()
  }

  setupAutocomplete() {
    const searchInput = document.getElementById('product-type-search')

    searchInput.addEventListener('input', async (event) => {
      const query = event.target.value
      if (query.length > 0) {
        const response = await fetch(`/deliveries/product_types/search?query=${query}`)
        const data = await response.json()
        this.updateProductType(data)
      } else {
        this.clearProductTypes()
      }
    })
  }

  updateProductType(productTypes) {
    this.productTypesTarget.innerHTML = ""
    if (productTypes.length === 0) {
      this.noResultTarget.style.display = "block"
    } else {
      this.noResultTarget.style.display = "none"
      productTypes.forEach(productType => {
        const productCard = document.createElement("div")
        productCard.className = "col-md-4 mb-2"
        productCard.innerHTML = `
          <div class="product-type-container" data-product-type-id="${productType.id}" data-product-type-name="${productType.name}">
            <p class="product-type-name">${productType.name}</p>
            <div class="input-group d-flex justify-content-between">
              <button class="quantity-btn" type="button" data-action="product-type-select#decreaseQuantity">-</button>
              <input type="number" class="quantity-input" value="1" min="1" data-product-type-select-target="quantity">
              <button class="quantity-btn" type="button" data-action="product-type-select#increaseQuantity">+</button>
            </div>
            <button class="add-btn text-muted" type="button" data-action="click->product-type-select#select">Add</button>
          </div>
        `
        this.productTypesTarget.appendChild(productCard)
      })
    }
  }

  clearProductTypes() {
    this.productTypesTarget.innerHTML = ""
    this.noResultTarget.style.display = "block"
  }

  increaseQuantity(event) {
    const input = event.target.closest(".input-group").querySelector("input")
    input.value = parseInt(input.value) + 1
  }

  decreaseQuantity(event) {
    const input = event.target.closest(".input-group").querySelector("input")
    if (input.value > 1) {
      input.value = parseInt(input.value) - 1
    }
  }

  selectOnConnect() {
    if (this.selectedListTarget.querySelectorAll("li").length > 0) {
      this.selectedListTarget.querySelectorAll("li").forEach((listItem) => {
        const productTypeId = listItem.dataset.productTypeId
        const productTypeDeliveryId = listItem.dataset.productTypeDeliveryId
        const productName = listItem.dataset.name
        const quantity = listItem.dataset.quantity
        this.selectedProductTypes.set(productTypeId, { id: productTypeDeliveryId, name: productName, quantity: parseInt(quantity) })
      })
      this.updateSelectedList()
      this.updateHiddenInputs()
    }
  }

  select(event) {
    const productTypeContainer = event.target.closest(".product-type-container")
    const productTypeId = productTypeContainer.dataset.productTypeId
    const productTypeDeliveryId = productTypeContainer.dataset.productTypeDeliveryId || null
    const productName = productTypeContainer.dataset.productTypeName
    const quantity = parseInt(productTypeContainer.querySelector("input").value)

    if (this.selectedProductTypes.has(productTypeId)) {
      this.selectedProductTypes.get(productTypeId).quantity += quantity
    } else {
      this.selectedProductTypes.set(productTypeId, { id: productTypeDeliveryId, name: productName, quantity: quantity })
    }

    if (this.deletedProductTypes.has(productTypeId)) {
      this.deletedProductTypes.delete(productTypeId)
    }

    this.updateSelectedList()
    this.updateHiddenInputs()
  }

  updateSelectedList() {
    this.selectedListTarget.innerHTML = ""
    this.selectedProductTypes.forEach((value, key) => {
      const listItem = document.createElement("li")
      listItem.className = "selected-product-type-item"
      listItem.dataset.productTypeId = key
      listItem.dataset.productTypeDeliveryId = value.id || ""
      listItem.dataset.name = value.name
      listItem.dataset.quantity = value.quantity
      listItem.innerHTML = `
        ${value.name} x ${value.quantity}
        <button class="remove-btn" data-action="click->product-type-select#remove" data-product-type-id="${key}">x</button>
      `
      this.selectedListTarget.appendChild(listItem)
    })
  }

  updateHiddenInputs() {
    this.inputsTarget.innerHTML = ""
    this.addSelectedProductTypeInputs()
    this.addDeletedProductTypeInputs()
  }

  addSelectedProductTypeInputs() {
    let index = 0
    this.selectedProductTypes.forEach((value, key) => {
      if (!this.deletedProductTypes.has(key)) {
        const hiddenInputId = this.createHiddenInput(`delivery[product_type_deliveries_attributes][${index}][product_type_id]`, key)
        const hiddenInputQuantity = this.createHiddenInput(`delivery[product_type_deliveries_attributes][${index}][quantity]`, value.quantity)
        if (value.id) {
          const hiddenInputRecordId = this.createHiddenInput(`delivery[product_type_deliveries_attributes][${index}][id]`, value.id)
          this.inputsTarget.appendChild(hiddenInputRecordId)
        }
        this.inputsTarget.appendChild(hiddenInputId)
        this.inputsTarget.appendChild(hiddenInputQuantity)
        index++
      }
    })
  }

  addDeletedProductTypeInputs() {
    let index = this.selectedProductTypes.size
    this.deletedProductTypes.forEach((value, key) => {
      const hiddenDestroyInput = this.createHiddenInput(`delivery[product_type_deliveries_attributes][${index}][id]`, value.id)
      const hiddenDestroyFlag = this.createHiddenInput(`delivery[product_type_deliveries_attributes][${index}][_destroy]`, "1")

      this.inputsTarget.appendChild(hiddenDestroyInput)
      this.inputsTarget.appendChild(hiddenDestroyFlag)
      index++
    })
  }

  createHiddenInput(name, value) {
    const input = document.createElement("input")
    input.type = "hidden"
    input.name = name
    input.value = value
    return input
  }

  remove(event) {
    const productTypeId = event.currentTarget.dataset.productTypeId
    if (this.selectedProductTypes.has(productTypeId)) {
      const productType = this.selectedProductTypes.get(productTypeId)
      this.selectedProductTypes.delete(productTypeId)
      this.deletedProductTypes.set(productTypeId, productType)
    }
    this.updateSelectedList()
    this.updateHiddenInputs()
  }
}
