// app/javascript/controllers/location_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["latitude", "longitude", "form", "button"]

  updateLocationAndStatus(event) {
    event.preventDefault();
    this.buttonTarget.disabled = true; // Disable button to prevent multiple clicks

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.success.bind(this), this.error.bind(this));
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }

  success(position) {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    this.latitudeTarget.value = latitude;
    this.longitudeTarget.value = longitude;
    this.formTarget.submit();
  }

  error(error) {
    console.error("Error getting location:", error);
    this.formTarget.submit();
  }
}
