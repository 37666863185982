import { Controller } from "@hotwired/stimulus"
import Autocomplete from "bootstrap5-autocomplete";

export default class extends Controller {
  connect() {
    this.setupAutocomplete();
  }

  disconnect() {
    document.removeEventListener('turbo:load', this.setupAutocomplete.bind(this));
  }

  setupAutocomplete() {
    const customerSearch = this.element.querySelector("input#customer_search");

    if (!customerSearch) return;

    const customerNameElement = document.querySelector("#customer_name");
    const customerPhoneElement = document.querySelector("#customer_phone_number");

    // Legacy fields
    const recipientNameElement = document.querySelector("#delivery_recipient_name");
    const recipientPhoneElement = document.querySelector("#delivery_recipient_phone");

    const secondLink = document.querySelector("#booking-customer-second-link");

    Autocomplete.init("input#customer_search", {
      highlightTyped: true,
      maximumItems: 5,
      fullWidth: true,
      hiddenInput: true,
      onSelectItem: (item, inputfield) => {
        // Assuming item object has `name` and `phone` properties
        customerNameElement.value = item.label;
        customerPhoneElement.value = item.phone_number;

        // Legacy fields
        recipientNameElement.value = item.label;
        recipientPhoneElement.value = item.phone_number;
        
        secondLink.href = `/customers/${item.value}/edit`;
        secondLink.textContent = `Edit customer`;
        secondLink.dataset.turboFrame = "form_modal";
        secondLink.classList.remove("d-none");
      }
    });

    // Listen for input events on the customer search field
    customerSearch.addEventListener('focus', () => {
      // Clear the customer search field
      customerSearch.value = '';
    });
  }
}
