import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    copyLink(event) {
        event.preventDefault();
        const link = event.currentTarget.dataset.link;
        navigator.clipboard.writeText(link).then(r => {
            alert("Payment link copied to clipboard");
        }, e => {
            alert("Failed to copy payment link");
        });
    }
}