import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="modal-form"
export default class extends Controller {

  populateFields(event) {
    const button = event.currentTarget
    document.getElementById("modal_delivery_mark_as_ready").value = button.dataset.markAsReady
    document.getElementById("modal_delivery_id").value = button.dataset.deliveryId
  }
}
