import { Application } from "@hotwired/stimulus"
import * as bootstrap from 'bootstrap'

const application = Application.start()
const BASE_ADDRESS = 'Berchvliet 14, Amsterdam, 1046CA'

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

export { application }

function onAddressChange(autocomplete, isDeliveryAddress) {
  if(!autocomplete) return;
  autocomplete.addListener("place_changed", function(e) {
    updateMap()
    getTravelTime()
    if (isDeliveryAddress) { updatePlaceId(autocomplete) }
  });
}

function updatePlaceId(autocomplete) {
  let place = autocomplete.getPlace();
  if (!place.place_id) return;
  document.getElementById('delivery_place_id').value = place.place_id;
}

function onDeliveryTypeChange() {
  const deliveryTypeEle = document.querySelector('input[name="delivery[delivery_type]"]')
  if (!deliveryTypeEle) return;
  deliveryTypeEle.addEventListener("change", function(e) {
    if(this.value == '1') return;
    getTravelTime()
    calculateExpectedTime()
  });
}

function onScheduleDateChange() {
  const scheduleDateEle = document.getElementById("delivery_scheduled_date")
  if (!scheduleDateEle) return;
  scheduleDateEle.addEventListener("change", function(e) {
    showScheduledDate()
  });
}

function choosePickupAddress() {
  const checkedPickupRadio = document.querySelector('input[name="delivery[pickup_address_id]"]:checked')
  if(checkedPickupRadio && checkedPickupRadio.value !== "") return checkedPickupRadio.labels[0].innerText

  const pickupAddressEle = document.getElementById("delivery_pickup_address")
  if(pickupAddressEle && pickupAddressEle.value !== "") return pickupAddressEle.innerText

  const altPickupEle = document.getElementById("delivery_alt_pickup_address")
  if(altPickupEle && altPickupEle.value !== "") return altPickupEle.value
  return ""
} 

function chooseDeliveryAddress() {
  const checkedDeliveryRadio = document.querySelector('input[name="delivery[delivery_address]"]:checked')
  if(checkedDeliveryRadio && checkedDeliveryRadio.value !== "") return checkedDeliveryRadio.labels[0].innerText

  const deliveryAddressEle = document.getElementById("delivery_delivery_address")
  if(deliveryAddressEle && deliveryAddressEle.value !== "") return deliveryAddressEle.value

  return ""
}

export function updateMap() {
  const pickupAddress = choosePickupAddress()
  const deliveryAddress = chooseDeliveryAddress()
  if(!deliveryAddress && !pickupAddress) return;

  const map = document.getElementById("google-embed-map")
  if(!map) return;

  var googleApiKey = map.getAttribute('data-google-api-key');
  if (deliveryAddress !== undefined && deliveryAddress !== "") {
    var mode = getTransportMode()
    var newMapUrl = `https://www.google.com/maps/embed/v1/directions?key=${googleApiKey}&origin=${pickupAddress}&destination=${deliveryAddress}&mode=${mode}`
  } else {
    var newMapUrl = `https://www.google.com/maps/embed/v1/place?key=${googleApiKey}&q=${pickupAddress}`
  }
  map.src = newMapUrl.toString()
}

function getTransportMode() {
  return "driving"
}

function getTravelTimeFromTo(pickupAddress, deliveryAddress) {
  return new Promise((resolve, reject) => {
    let service = new google.maps.DistanceMatrixService();
    service.getDistanceMatrix({
      origins: [pickupAddress],
      destinations: [deliveryAddress],
      travelMode: getTransportMode().toUpperCase()
    }, function (response, status) {
      if (status === 'OK') {
        resolve(response.rows[0].elements[0].duration.value / 60);
      } else {
        reject('Error occurred: ' + status);
      }
    });
  });
}

export function getTravelTime() {
  const pickupAddress = choosePickupAddress()
  if(!pickupAddress) return;

  const deliveryAddress = chooseDeliveryAddress()
  if(!deliveryAddress) return;

  const travelTime = document.getElementById("delivery_travel_time")
  if(!travelTime || deliveryAddress == '') return;
  
  let service = new google.maps.DistanceMatrixService();
  service.getDistanceMatrix(
    {
      origins: [pickupAddress],
      destinations: [deliveryAddress],
      travelMode: getTransportMode().toUpperCase()
    }, travelTimeCallback
  );
}

function travelTimeCallback(response, status) {
  // See Parsing the Results for
  // the basics of a callback function.
  if (status !== 'OK') {
    console.error('Unable to retrieve travel time from google. The error was: ' + status);
    return;
  }
  const travelTime = document.getElementById("delivery_travel_time")
  if(!travelTime) return;
  const travelTimeInMinutes = response.rows[0].elements[0].duration.value / 60
  travelTime.value = travelTimeInMinutes
  toggleDeliveryTimeContainer(travelTimeInMinutes)
}

const outsideDeliveryRange = async () => {
  const timeFromBaseToPickup = await getTravelTimeFromTo(BASE_ADDRESS, choosePickupAddress());
  const timeFromBaseToDelivery = await getTravelTimeFromTo(BASE_ADDRESS, chooseDeliveryAddress());
  return (timeFromBaseToPickup > 15 && timeFromBaseToDelivery > 30);
}

function toggleDeliveryTimeContainer(travelTime){
  if(chooseMaxTravelTime() !== null && travelTime > chooseMaxTravelTime()) {
    document.getElementById("outside-delivery-range-message").classList.remove("d-none")
    document.getElementById("latest-time-container").classList.add("d-none")
    document.querySelectorAll(".delivery-form-submit-button").forEach(function(ele) {
      ele.disabled = true
    })
  } else {
    document.getElementById("outside-delivery-range-message").classList.add("d-none")
    document.getElementById("latest-time-container").classList.remove("d-none")
    document.querySelectorAll(".delivery-form-submit-button").forEach(function(ele) {
      ele.disabled = false
    })
    calculateExpectedTime()
  }
}

function chooseMaxTravelTime() {
  let transportMode = getTransportMode()
  let checkedPickupRadio = document.querySelector('input[name="delivery[pickup_address_id]"]:checked')

  if(checkedPickupRadio) {
    let checkedPickupID = parseInt(checkedPickupRadio.value)
    if(!document.getElementById("teams-max-travel-times")) return null;
    const teamMaxTravelTimes = JSON.parse(document.getElementById("teams-max-travel-times").innerText)
    const teamMaxTravelTime = teamMaxTravelTimes.find(teamMaxTravelTime => teamMaxTravelTime.address_id == checkedPickupID)
    if(!teamMaxTravelTime) return null;

    if(transportMode == "driving") return parseInt(teamMaxTravelTime.max_travel_time_driving)
    if(transportMode == "bicycling") return parseInt(teamMaxTravelTime.max_travel_time_bicycling)
  } else {
    if(!document.getElementById(`team-max-travel-time-${transportMode}`)) return null;
    return parseInt(document.getElementById(`team-max-travel-time-${transportMode}`).innerText)
  }
}

function initPlacesAutocomplete(element) {
  if (!element) return;
  return new google.maps.places.Autocomplete(element, {componentRestrictions: { country: 'nl' }});
}

function formatTime(date) {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}`;
}

function calculateExpectedTime() {
  // if the delivery type is scheduled then we don't need to calculate the expected time
  const deliveryTypeEle = document.querySelector('input[name="delivery[delivery_type]"]:checked');
  if(deliveryTypeEle && deliveryTypeEle.value === 'scheduled') {
    showScheduledDate();
    return;
  }
  
  const travelTimeEle = document.getElementById("delivery_travel_time");
  if(!travelTimeEle) return;
  
  const travelTime = parseInt(travelTimeEle.value, 10);
  const expectedTime = document.querySelector(".expected-time");
  const expectedDay = document.getElementById("expected-day");

  if(!expectedTime || !travelTime) return;
  
  const prepTime = 20;
  const expectedTimeInMinutes = travelTime + prepTime;
  
  // Calculate start and end times
  const startTime = addMinutesToCurrentDate(expectedTimeInMinutes);
  const endTime = new Date(startTime);
  endTime.setHours(endTime.getHours() + 4);

  // Format the times
  const startTimeStr = formatTime(startTime);
  const endTimeStr = formatTime(endTime);

  // Update the displayed times
  expectedTime.innerText = `${startTimeStr} - ${endTimeStr}`;

  // Update the expected day
  expectedDay.innerText = (startTime.getDate() === endTime.getDate()) ? 'Today' : 'Tomorrow';
}

export function showScheduledDate() {
  const scheduleDateEle = document.getElementById('delivery_scheduled_date')
  const selectedTimeSlotEle = document.querySelector('input[name="delivery[time_slot]"]:checked')
  if(!scheduleDateEle || !selectedTimeSlotEle ) return;

  const scheduleDate = scheduleDateEle.value
  const selectedTimeSlot = selectedTimeSlotEle.value
  const expectedTime = document.querySelector(".expected-time")
  const expectedDay = document.getElementById("expected-day")
  if(!scheduleDate || !expectedTime) return;

  const timeSlotStep = parseInt(localStorage.getItem('timeSlotStep')) || 1
  const timeToDisplay = new Date(`${scheduleDate}T${selectedTimeSlot}`)
  expectedTime.innerText = `${padTo2Digits(timeToDisplay.getHours())}:${padTo2Digits(timeToDisplay.getMinutes())} - ${padTo2Digits(timeToDisplay.getHours() + timeSlotStep)}:${padTo2Digits(timeToDisplay.getMinutes())}`

  if (timeToDisplay.getDate() == new Date().getDate()) {
    expectedDay.innerText = 'Today'
  } else if (timeToDisplay.getDate() == new Date().getDate() + 1) {
    expectedDay.innerText = 'Tomorrow'
  } else {
    expectedDay.innerText = `${padTo2Digits(timeToDisplay.getDate())}/${padTo2Digits(timeToDisplay.getMonth() + 1)}/${timeToDisplay.getFullYear()}`
  }
}

function addMinutesToCurrentDate(minutes) {
  const currentDate = new Date()
  return new Date(currentDate.getTime() + minutes * 60000)
}

export function padTo2Digits(num) {
  return String(num).padStart(2, '0');
}

function openAccordionIfErrors() {
  const deliveryForm = document.getElementById('delivery-form');
  if (!deliveryForm) return;

  const formInputs = [
    ...deliveryForm.querySelectorAll('input'),
    ...deliveryForm.querySelectorAll('textarea'),
    ...deliveryForm.querySelectorAll('select')
  ];

  if (formInputs.length === 0) return;

  formInputs.forEach(function(input) {
    input.addEventListener('invalid', function(e) {
      const accordion = input.closest('.accordion-collapse');
      if (!accordion) return;

      // Check if the accordion is already open
      if (!accordion.classList.contains('show')) {
        new bootstrap.Collapse(accordion, { toggle: true });
      }
    });
  });
}

function scrollAccordionButton() {
  const accordionButtons = document.querySelectorAll('.accordion-button');

  accordionButtons.forEach(function(button) {
    button.addEventListener('click', function(e) {
      const rect = e.currentTarget.getBoundingClientRect();
      const scrollTo = window.scrollY + rect.top - 1000;
      window.scrollTo({
        top: scrollTo,
        behavior: 'smooth'  // smooth scroll
      });
    });
  });
}


document.addEventListener("turbo:load", function() {
  showToasts();
});

document.addEventListener("turbo:before-stream-render", function() {
  setTimeout(showToasts, 100);
});

function showToasts() {
  var toasts = document.querySelectorAll('.toast');
  toasts.forEach(function(toast) {
    new bootstrap.Toast(toast).show();
  });
}

function isEditForm() {
  return document.getElementById('delivery_id') !== null
}


document.addEventListener('DOMContentLoaded', () => {
  var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
  var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl)
  })

  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  })

  scrollAccordionButton()
  openAccordionIfErrors()
  updateMap()
  if(!isEditForm()) {
    getTravelTime()
    calculateExpectedTime()
  }
  showScheduledDate()
  let deliveryAddressAutocomplete = initPlacesAutocomplete(document.getElementById("delivery_delivery_address"))
  onAddressChange(deliveryAddressAutocomplete, true)
  let pickupAddressAutocomplete = initPlacesAutocomplete(document.getElementById("delivery_alt_pickup_address"))
  onAddressChange(pickupAddressAutocomplete, false)
  onDeliveryTypeChange()
  onScheduleDateChange()
})