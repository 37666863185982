import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["target", "template"];
    static values = {
        wrapperSelector: {
            type: String,
            default: ".nested-items-wrapper"
        }
    };

    connect() {
        this.counter = 0;
        this.replaceIds();
        this.handleLastItem();
    }

    add(event) {
        event.preventDefault();

        const originalTemplate = this.templateTarget.innerHTML;
        const e = originalTemplate.replace(/NEW_RECORD/g, this.counter);
        const modifiedTemplate = e.replace(/NEW_INPUT/g, `input_${this.counter}`);
        this.targetTarget.insertAdjacentHTML("beforeend", modifiedTemplate);

        this.counter++;
        this.handleLastItem();
    }

    remove(event) {
        event.preventDefault();
        const e = event.target.closest(this.wrapperSelectorValue);
        if (e.dataset.newRecord === "true")
            e.remove();
        else {
            e.style.display = "none";
            const a = e.querySelector("input[name*='_destroy']");
            a.value = "1";
        }
        this.handleLastItem();

        // Dispatch custom event to notify of item removal
        this.element.dispatchEvent(new CustomEvent('itemRemoved', { bubbles: true }));
    }

    handleLastItem() {
        if (this.childrenCount() === 1)
            this.targetTarget.firstElementChild.querySelector(".remove").style.display = "none";
        else
            this.targetTarget.firstElementChild.querySelector(".remove").style.display = "block";
    }

    childrenCount() {
        const nestedItemWrappers = document.querySelectorAll('.nested-items-wrapper');
        let visibleCount = 0;

        // Loop through the elements and count those without "display: none" style
        nestedItemWrappers.forEach(element => {
            if (window.getComputedStyle(element).display !== 'none') {
                visibleCount++;
            }
        });

        return visibleCount;
    }

    // go through all the nested items and replace the ids
    replaceIds() {
        this.targetTarget.querySelectorAll('.nested-items-wrapper').forEach((block) => {
            block.innerHTML = block.innerHTML.replace(/NEW_RECORD/g, this.counter);
            block.innerHTML = block.innerHTML.replace(/NEW_INPUT/g, `input_${this.counter}`);
            this.counter++;
        });
    }
}