import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs";

export default class extends Controller {
  connect() {
    this.initializeSortable();
  }

  initializeSortable() {
    this.sortable = Sortable.create(this.element, {
      dataIdAttr: 'data-route-stop-id',
      handle: ".bi-arrows-move",
      onEnd: this.onSortEnd.bind(this)
    });
  }

  onSortEnd(event) {
    console.log(`Moved delivery from index ${event.oldIndex} to index ${event.newIndex}`);
    this.updateRouteOrder();
  }

  updateRouteOrder() {
    try {
      const routeStopIds = this.sortable.toArray().join(' ');
      this.updateMapWithNewWaypoints(routeStopIds);

      this.updateRouteStopsOrderHiddenInputField(routeStopIds);
    } catch (error) {
      console.error('Error updating order:', error);
    }
  }

  updateMapWithNewWaypoints(routeStopIds) {
    const newWaypoints = this.extractAddressesFromOrder(routeStopIds).join('|').replace(/ /g, '+');
    this.updateMap(newWaypoints);
  }

  updateRouteStopsOrderHiddenInputField(routeStopIds) {
    document.getElementById('route_route_stops_order').value = routeStopIds;
  }

  updateMap(newWaypoints) {
    const mapIframe = this.getMapIframe();
    const mapUrl = new URL(mapIframe.src);
    mapUrl.searchParams.set('waypoints', newWaypoints);
    mapIframe.src = mapUrl.toString();
  }

  extractAddressesFromOrder(routeStopIds) {
    const addresses = [];
    let lastPickupAddress = null;
    const stopIds = routeStopIds.split(' ');
    const originAddress = this.getOriginAddress();

    stopIds.forEach(stopId => {
      const stopElement = this.getStopElement(stopId);
      if (stopElement) {
        this.processStopElement(stopElement, addresses, lastPickupAddress, originAddress);
      } else {
        console.error(`Address not found for delivery ID: ${stopId}`);
      }
    });

    return addresses;
  }

  processStopElement(stopElement, addresses, lastPickupAddress, originAddress) {
    const deliveryAddress = stopElement.dataset.deliveryAddress;
    // const pickupAddress = stopElement.dataset.pickupAddress;

    // if (this.shouldAddPickupAddress(pickupAddress, lastPickupAddress, originAddress)) {
    //   addresses.push(pickupAddress);
    //   lastPickupAddress = pickupAddress;
    // }

    if (deliveryAddress) {
      addresses.push(deliveryAddress);
    }
  }

  shouldAddPickupAddress(pickupAddress, lastPickupAddress, originAddress) {
    return pickupAddress && pickupAddress !== lastPickupAddress && pickupAddress !== originAddress;
  }

  getMapIframe() {
    return document.getElementById('google-embed-map');
  }

  getStopElement(stopId) {
    return document.querySelector(`tbody[data-route-stop-id="${stopId}"]`);
  }

  getOriginAddress() {
    const mapIframe = this.getMapIframe();
    const mapUrl = new URL(mapIframe.src);
    return mapUrl.searchParams.get('origin');
  }

  disconnect() {
    this.sortable.destroy();
  }

  extractDeliveryIdsFromStopIds(routeStopIds) {
    return routeStopIds.split(' ').map(stopId => {
      return Array.from(
        this.getStopElement(stopId).querySelectorAll('.deliveries')).map(delivery =>
          delivery.dataset.deliveryId
        )
    }).join(',').replace(/,/g, ' ');
  }
}
