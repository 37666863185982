import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="application"
export default class extends Controller {
  static targets = [ "deliveryModal", "deliveredButton" ]

  connect() {
    console.log('Application controller connected');
  }

  updateModalDeliveryId(e) {
    const deliveryId = e.target.getAttribute('data-delivery-id')
    const modalHiddenDeliveryId = document.getElementById('modal-hidden-delivery-id')
    if(!modalHiddenDeliveryId) return;
    modalHiddenDeliveryId.setAttribute('value', deliveryId)
  }
}
