import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="delivered-modal"
export default class extends Controller {
  static targets = [ "skipPhotoHiddenField", "form" ]

  connect() {
  }

  skipPhoto() {
    this.formTarget.submit();
  }

  deliverNextButtonClicked(event) {
    document.getElementById("button-clicked").value = "deliver_next";
  }
}
