// time_slot_controller.js
import { Controller } from "@hotwired/stimulus"
import { showScheduledDate } from "./application"
import { Datepicker } from "vanillajs-datepicker"
import dateFormat from "dateformat"

export default class extends Controller {
  static targets = ["slotContainer", "hiddenSlot", "timeSlotInput", "scheduledDate"]

  async connect() {
    this.isAdmin = this.element.dataset.isAdmin === "true";
    this.currentDate = new Date(dateFormat(new Date(), "yyyy-mm-dd"))
    this.timeSlotStep = await this.fetchTimeSlotStep() || 1
    this.updateTimeSlots()
    this.createDatePicker()
  }

  createDatePicker() {
    const weekends = this.getWeekends()
    this.datepicker = new Datepicker(this.scheduledDateTarget, {
      format: "yyyy-mm-dd",
      autohide: true,
      weekStart: 1,
      daysOfWeekDisabled: weekends,
      minDate: this.currentDate,
      maxDate: new Date(this.currentDate).setDate(this.currentDate.getDate() + 14)
    })
    this.updateDatePickerValue()
  }

  updateDatePickerValue() {
    const selectedDate = new Date(this.scheduledDateTarget.value)
    if (selectedDate <= this.currentDate) {
      this.scheduledDateTarget.value = dateFormat(selectedDate, "yyyy-mm-dd")
    } else {
      this.enableAllSlots()
    }
  }

  disableOldTimeSlots() {
    if(this.timeSlotInputTargets.length === 0) return
    // Disable all time slots before current time
    const currentDate = new Date()
    const currentHour = currentDate.getHours()
    const currentMinutes = currentDate.getMinutes()
    const currentDay = currentDate.getDay()
    const currentDayTimeSlots = this.getTimeSlots(currentDay)

    currentDayTimeSlots.forEach((timeSlot, i) => {
      const timeSlotHour = parseInt(timeSlot.split(":")[0])
      const timeSlotMinutes = parseInt(timeSlot.split(":")[1])

      if (timeSlotHour < currentHour) {
        this.timeSlotInputTargets[i].setAttribute("disabled", "disabled");
      } else if (timeSlotHour == currentHour && timeSlotMinutes <= currentMinutes) {
        this.timeSlotInputTargets[i].setAttribute("disabled", "disabled");
      }
    })
  }

  enableAllSlots() {
    this.timeSlotInputTargets.forEach(input => input.removeAttribute("disabled"))
  }

  updateTimeSlots() {
    const dayOfWeek = new Date(this.scheduledDateTarget.value).getDay()
    const scheduledDate = new Date(this.scheduledDateTarget.value)
    let timeSlots = this.filterSlotsByAvailability(this.getTimeSlots(dayOfWeek), scheduledDate)
    if(timeSlots.length === 0) {
       // Set the scheduled date to the next available date
      // and update the time slots
      const nextAvailableDate = new Date(this.scheduledDateTarget.value)
      const weekends = this.getWeekends()
      // Increment the date until we find a day that is not a weekend
      do {
        nextAvailableDate.setDate(nextAvailableDate.getDate() + 1)
      } while (weekends.includes(nextAvailableDate.getDay()))
      
      this.scheduledDateTarget.value = dateFormat(nextAvailableDate, "yyyy-mm-dd")
      this.updateTimeSlots()
    } else {
      this.slotContainerTarget.classList.remove('d-none')
      this.slotContainerTarget.innerHTML = timeSlots.map((timeSlot, i) => this.generateSlotHtml(timeSlot, this.hiddenSlotTarget.value, i)).join('')
    }
    this.onChangeDate()
  }

  // Helper to format the day of the week for backend compatibility
  formatDayOfWeekForBackend(day) {
    if(day === 0) {
      return 7
    } else if (day === 7) {
      return 0
    } else {
      return day
    }
  }

  // Helper to filter out slots based on availability and time constraints
  filterSlotsByAvailability(timeSlots, scheduledDate) {
    // Securely determine if the current user is an admin
    if (this.isAdmin) {
      // If admin, return all time slots without filtering
      return timeSlots;
    }

    const fourHours = 4 * 60 * 60 * 1000
    const currentTimePlusFourHours = new Date(new Date().getTime() + fourHours)
    return timeSlots.filter(timeSlot => {
      const slotDateTime = new Date(`${dateFormat(this.currentDate, "yyyy-mm-dd")}T${timeSlot}`)
      return scheduledDate.getDate() === this.currentDate.getDate()
        ? slotDateTime.getTime() > currentTimePlusFourHours.getTime()
        : true
    })
  }

  // Helper to generate HTML for a time slot
  generateSlotHtml(timeSlot, currentTimeSlot, index) {
    const nextHourTimeSlot = this.getNextHour(timeSlot)
    const checkEarliestTimeSlot = this.slotContainerTarget.dataset.checkEarliest === "true" && index === 0
    const checkedAttribute = (currentTimeSlot === timeSlot || checkEarliestTimeSlot) ? 'checked="checked"' : ''

    return `
      <input class="vehicle-radio-input"
        type="radio"
        name="delivery[time_slot]"
        id="time_slot_${index}"
        value="${timeSlot}"
        ${checkedAttribute}
        data-action="change->time-slot#updateHiddenTimeSlot change->time-slot#updateExpectedTime change->delivery-price#quote"
        data-delivery-price-target="timeSlotInput"
        data-time-slot-target="timeSlotInput"
      >
      <label class="col-6 col-md-3 vehicle-radio-label d-flex align-items-center justify-content-center" for="time_slot_${index}">
        ${timeSlot} - ${nextHourTimeSlot}
      </label>
    `
  }

  // Helper to get the next hour for a given time slot
  getNextHour(timeSlot) {
    const date = new Date(`1970-01-01T${timeSlot}:00Z`)
    date.setHours(date.getHours() + this.timeSlotStep)
    return `${String(date.getUTCHours()).padStart(2, '0')}:${String(date.getUTCMinutes()).padStart(2, '0')}`
  }

  // Helper to get weekends based on time slots data
  getWeekends() {
    return Object.entries(this.timeSlotsData)
      .filter(([_, data]) => data.weekend)
      .map(([day, _]) => this.formatDayOfWeekForBackend(parseInt(day)))
  }

  // Helper to get time slots for a specific day
  getTimeSlots(day) {
    const formattedDay = this.formatDayOfWeekForBackend(day)
    return this.timeSlotsData[formattedDay]?.time_slots || []
  }

  // Additional methods to implement functionality
  updateExpectedTime() {
    showScheduledDate()
  }

  updateHiddenTimeSlot() {
    const selectedTimeSlot = this.timeSlotInputTargets.find(input => input.checked)?.value
    this.hiddenSlotTarget.value = selectedTimeSlot || ''
  }

  get timeSlotsData() {
    return JSON.parse(this.element.dataset.timeslots)
  }

  onChangeDate() {
    const scheduledDateVal = this.scheduledDateTarget.value
    this.fetchTimeSlotsAvailability(scheduledDateVal)
    this.updateDatePickerValue()
  }

  async fetchTimeSlotStep(){
    const addressId = document.getElementById('delivery_pickup_address_id')
    if(!addressId) return 1; // Default step if no address ID found or any other fallback
  
    try {
      const response = await fetch(`/time_slots/time_slot_step?address_id=${addressId.value}`);
      if (!response.ok) throw new Error('Network response was not ok.');
      const timeSlotStepData = await response.json();
      localStorage.setItem('timeSlotStep', timeSlotStepData.step); // Store the step in localStorage
      return timeSlotStepData.step; // Ensure this matches your JSON structure
    } catch (error) {
      console.error('Error fetching time slot step:', error);
      return 1; // Fallback step
    }
  }

  async fetchTimeSlotsAvailability(scheduledDateVal) {
    try {
      const response = await fetch(`/timeslots-availability?date=${scheduledDateVal}`)
      if (!response.ok) throw new Error('Network response was not ok.')
      const availabilityData = await response.json()
      this.updateTimeSlotsWithAvailability(availabilityData)
    } catch (error) {
      console.error('Error fetching timeslot availability:', error)
    }
  }

  updateTimeSlotsWithAvailability(availabilityData) {
    this.timeSlotInputTargets.forEach(input => {
      const available = availabilityData[input.value]
      if (available) {
        input.removeAttribute("disabled")
      } else {
        input.setAttribute("disabled", "disabled")
      }
    })
  }
}
