import {Controller} from "@hotwired/stimulus"


export default class extends Controller {
    static targets = ["reasonSelect", "reasonMessageInput", "reasonMessageLabel"]

    toggleRequired() {
        let select = this.reasonSelectTarget;
        let input = this.reasonMessageInputTarget;
        let label = this.reasonMessageLabelTarget;
        if (select.options[select.selectedIndex].text === "Other") {
            input.required = true;
            label.innerText += " *"
        } else if (label.innerText.endsWith('*')) {
            input.required = false;
            label.innerText = label.innerText.slice(0, -2);
        }
    }
}
