import { Controller } from "@hotwired/stimulus"
import intlTelInput from "intl-tel-input";

// Connects to data-controller="phone"
export default class extends Controller {
  static targets = ["phoneInput"]

  connect() {
    this.iti = intlTelInput(this.phoneInputTarget, {
      initialCountry: "nl",
      countryOrder: ["nl"],
      loadUtilsOnInit: `https://cdn.jsdelivr.net/npm/intl-tel-input@${intlTelInput.version}/build/js/utils.js`
    });

    // Perform validation on blur or form submit
    this.phoneInputTarget.addEventListener("blur", this.formatPhoneNumber.bind(this));
  }

  formatPhoneNumber() {
    // Ensure utils.js is loaded
    if (this.iti.isValidNumber()) {
      const fullNumber = this.iti.getNumber(intlTelInput.utils.numberFormat.E164);
      this.phoneInputTarget.value = fullNumber; // Replace the input value with the formatted full number
    } else {
      this.phoneInputTarget.setCustomValidity("Please enter a valid phone number.");
    }
  }  
}
