import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"

export default class extends Controller {
  static targets = ["deleteModal", "form"]

  
  openModal(event) {
    event.preventDefault();
    const shiftId = event.currentTarget.dataset.shiftId;
    this.formTarget.action = `/shifts/${shiftId}`;
    new Modal(this.deleteModalTarget).show();
  }

  confirmDelete(event) {
    event.preventDefault();
    const cancelFutureShifts = event.currentTarget.dataset.cancelFutureShifts === 'true';
    this.formTarget.querySelector("#cancel_future_shifts").value = cancelFutureShifts;
    this.formTarget.submit();
  }
}
