import { Controller } from "@hotwired/stimulus"
import { updateMap, getTravelTime} from "./application.js"

// Connects to data-controller="address"
export default class extends Controller {
  static targets = ["addressOptions", "altAddressInputContainer", "altAddressInput"]

  toggleInput(event) {
    let selectedOption = this.chooseSelectedOption(event.target.name)
    let altAddressInputContainer = this.chooseAddressInputContainer(selectedOption.name)
    let altAddressInput = this.chooseAddressInput(selectedOption.name)

    if(selectedOption.value == "") {
      altAddressInputContainer.classList.remove("d-none")
      altAddressInput.required = true
      altAddressInput.value = ""
      return
    } else {
      altAddressInputContainer.classList.add("d-none")
      altAddressInput.value = selectedOption.value
      altAddressInput.required = false
    }
    updateMap()
    getTravelTime()
  }

  chooseSelectedOption(name) {
    return this.addressOptionsTargets.find(obj => {return obj.checked === true && obj.name === name})
  }

  chooseAddressInputContainer(name) {
    if(name == "delivery[pickup_address_id]") {
      return this.altAddressInputContainerTargets.find(obj => {return obj.id == "delivery_pickup_address_container"})
    } else if (name == "delivery[delivery_address]") {
      return this.altAddressInputContainerTargets.find(obj => {return obj.id == "delivery_delivery_address_container"})
    }
  }

  chooseAddressInput(name) {
    if(name == "delivery[pickup_address_id]") {
      return this.altAddressInputTargets.find(obj => {return obj.id == "delivery_alt_pickup_address"})
    } else if (name == "delivery[delivery_address]") {
      return this.altAddressInputTargets.find(obj => {return obj.id == "delivery_delivery_address"})
    }
  }

  updateAuto() {
    this.altAddressInputTargets.forEach(target => {
      if(target.dataset.autocompleteTypes == null){
        new google.maps.places.Autocomplete(target, {
          componentRestrictions: { country: 'nl' }
        });
      } else {
        new google.maps.places.Autocomplete(target, {
          types: target.dataset.autocompleteTypes.split(','),
          componentRestrictions: { country: 'nl' }
        });
      }
    });
  }
}
