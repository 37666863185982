import { Controller } from "@hotwired/stimulus"
import { updateMap, getTravelTime } from "./application.js"

// Connects to data-controller="vehicle-select"
export default class extends Controller {
  static targets = ["vehicleType"]

  updateMap(){
    updateMap()
    getTravelTime()
  }
}
