import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "form", "loadingSpinner", "input", "riderSelect", "teamSelect", "statusSelect", "expectedAtFrom", "expectedAtTo", "filters" ]

  connect() {
    this.element.addEventListener('turbo:render', () => {
      this.hideLoadingSpinner()
    })
    this.closeFiltersOnMobile()
  }

  search() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.showLoadingSpinner()
      this.formTarget.requestSubmit()
    }, 3000)
  }

  statusSelectChanged() {
    this.showLoadingSpinner()
    this.formTarget.requestSubmit()
  }

  riderSelectChanged() {
    this.showLoadingSpinner()
    this.formTarget.requestSubmit()
  }

  teamSelectChanged() {
    this.showLoadingSpinner()
    this.formTarget.requestSubmit()
  }

  expectedAtFromChanged() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.showLoadingSpinner()
      this.formTarget.requestSubmit()
    }, 800)
  }

  expectedAtToChanged() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.showLoadingSpinner()
      this.formTarget.requestSubmit()
    }, 800)
  }

  showLoadingSpinner() {
    this.loadingSpinnerTarget.classList.remove("d-none")
  }

  hideLoadingSpinner() {
    this.loadingSpinnerTarget.classList.add("d-none")
  }
  
  moveCursorToEnd() {
    const input = this.inputTarget
    input.focus()
    input.setSelectionRange(input.value.length, input.value.length)
  }

  closeFiltersOnMobile() {
    if (window.innerWidth < 768) {
      this.filtersTarget.classList.remove("show")
    }
  }
}
