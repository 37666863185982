import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="bulk-assignment"
export default class extends Controller {
  static targets = ["deliveryCheckbox", "toggleAll", "hiddenDeliveryIds"]

  toggleAllDeliveries() {
    const isChecked = this.toggleAllTarget.checked;
    this.deliveryCheckboxTargets.forEach((checkbox) => {
      checkbox.checked = isChecked;
    });
    this.updateDeliveryIds();
  }

  updateDeliveryIds() {
    const selectedDeliveryIds = this.deliveryCheckboxTargets
                                  .filter(cb => cb.checked)
                                  .map(cb => cb.value)
                                  .join(',');

    this.hiddenDeliveryIdsTarget.value = selectedDeliveryIds;
  }

  checkboxChanged() {
    this.updateDeliveryIds();
  }
}
