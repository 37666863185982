import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "type",
    "deliveryBar",
    "itemsBar",
    "order",
    "orderField",
    "description",
    "descriptionField",
    "items",
    "disabledItems",
    "vehicle",
    "orderTotal",
    "itemPriceInput",
    "itemLabelInput"
  ];

  static values = {
    isSupplier: Boolean,
    isActive: Boolean
  };

  connect() {
    this.setActiveButtonOnLoad();
    this.updateView();
  }

  setActiveButtonOnLoad() {
    const type = this.typeTarget.value;

    if (type === "items") {
      this.setActiveButton(this.itemsBarTarget, this.deliveryBarTarget);
    } else if (type === "order_number") {
      this.setActiveButton(this.deliveryBarTarget, this.itemsBarTarget);
    }
  }

  toggle(event) {
    const button = event.currentTarget;
    const targetType = button.dataset.value;

    if (targetType === "items") {
      this.setActiveButton(button, this.deliveryBarTarget);
      this.typeTarget.setAttribute("value", "items");
    } else if (targetType === "order_number") {
      this.setActiveButton(button, this.itemsBarTarget);
      this.typeTarget.setAttribute("value", "order_number");
    }

    this.updateView();
  }

  setActiveButton(activeButton, inactiveButton) {
    activeButton.classList.add("active");
    inactiveButton.classList.remove("active");
  }

  showItemsView() {
    this.setRequiredAttribute();
    if (this.isSupplierValue) {
      this.showItemsSupplierView();
    } else {
      this.itemsTarget.classList.remove("d-none");
    }
    this.orderTotalTarget.classList.add("d-none");
    this.orderTarget.classList.add("d-none");
    this.orderFieldTarget.removeAttribute("required");
    this.descriptionTarget.classList.add("d-none");
    this.descriptionFieldTarget.removeAttribute("required");
  }

  showItemsSupplierView() {
    if (this.isActiveValue) {
      this.itemsTarget.classList.remove("d-none");
    } else {
      this.disabledItemsTarget.classList.remove("d-none");
      this.vehicleTarget.classList.add("d-none");
    }
  }

  showOrderView() {
    this.removeRequiredAttribute();
    this.itemsTarget.classList.add("d-none");
    if (this.isSupplierValue) {
      this.disabledItemsTarget.classList.add("d-none");
      this.vehicleTarget.classList.remove("d-none");
    }
    this.orderTotalTarget.classList.remove("d-none");
    this.orderTarget.classList.remove("d-none");
    this.orderFieldTarget.setAttribute("required", true);
    this.descriptionTarget.classList.remove("d-none");
    this.descriptionFieldTarget.setAttribute("required", true);
  }

  setRequiredAttribute() {
    this.itemPriceInputTargets.forEach((item) => {
      item.setAttribute("required", true);
    });
    this.itemLabelInputTargets.forEach((price) => {
      price.setAttribute("required", true);
    });
  }

  removeRequiredAttribute() {
    this.itemPriceInputTargets.forEach((item) => {
      item.removeAttribute("required");
    });
    this.itemLabelInputTargets.forEach((price) => {
      price.removeAttribute("required");
    });
  }

  updateView() {
    const type = this.typeTarget.value;

    if (type === "items") {
      this.showItemsView();
    } else if (type === "order_number") {
      this.showOrderView();
    }
  }
}
