import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="inline-edit"
export default class extends Controller {
  static targets = ["field", "form", "input"]

  connect() {
    this.fieldsOriginalValue = new Map();
    this.fieldTargets.forEach(field => {
      this.fieldsOriginalValue.set(field, field.textContent.trim());
    });
  }

  edit(event) {
    let field = event.currentTarget.parentElement;
    let form = field.nextElementSibling; // Assuming form is right after the field in your HTML structure
    let input = form.querySelector('[data-inline-edit-target="input"]');

    field.classList.add('d-none');
    form.classList.remove('d-none');
    input.focus();
  }

  cancel(event) {
    let form = event.currentTarget.parentElement.parentElement;
    let field = form.previousElementSibling; // Assuming field is right before the form in your HTML structure
    let input = form.querySelector('[data-inline-edit-target="input"]');

    input.value = this.fieldsOriginalValue.get(field);
    form.classList.add('d-none');
    field.classList.remove('d-none');
  }
}
