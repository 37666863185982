import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["spinnerElement"];

  connect() {
    // Listen to Turbo-Stream submit end event on the closest form
    const form = this.findParentForm();
    if (form) {
      form.addEventListener("turbo:submit-end", this.handleFormSubmitEnd.bind(this));
    }
  }

  toggle(event) {
    event.preventDefault();

    const form = this.findParentForm();
    if (form.checkValidity()) {
      this.showSpinner();
      this.delayAndSubmitForm();
    } else {
      this.hideSpinner();
    }
  }

  showSpinner() {
    const spinner = this.spinnerElementTarget;
    spinner.classList.remove("d-none");
    spinner.setAttribute("aria-hidden", "false");
  }

  hideSpinner() {
    const spinner = this.spinnerElementTarget;
    spinner.classList.add("d-none");
    spinner.setAttribute("aria-hidden", "true");
  }

  delayAndSubmitForm() {
    setTimeout(() => {
      const form = this.findParentForm();
      if (form) {
        form.requestSubmit();
      }
    }, 1000);
  }

  findParentForm() {
    return this.element.closest('form');
  }

  handleFormSubmitEnd(event) {
    // Check if the submission was successful
    if (!event.detail.success) {
      this.hideSpinner();
    }
  }
}
