// app/javascript/controllers/money_input_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    connect() {
        this.element.dataset.amount = "";
    }
    formatMoney(event) {
        if (event.inputType === "deleteContentBackward") {
            this.element.dataset.amount = this.element.dataset.amount.slice(0, -1);
        } else {
            this.element.dataset.amount += event.data;
        }

        const input = this.element.querySelector("input");
        const value = this.parseMoneyValue(this.element.dataset.amount);

        if (!isNaN(value)) {
            input.value = this.formatCurrency(value);
        }
    }

    parseMoneyValue(value) {
        // Remove all non-numeric characters except the last comma (,)
        const cleanedValue = value.replace(/[^\d,]/g, "").replace(/(,.*),/g, "$1");
        const floatValue = parseFloat(cleanedValue.replace(",", "."));

        return isNaN(floatValue) ? "" : floatValue.toFixed(2);
    }

    formatCurrency(value) {
        return new Intl.NumberFormat("nl-NL", {
            style: "currency",
            currency: "EUR",
            minimumFractionDigits: 2,
        }).format(value);
    }
}
